<template>
	<div class="page-container">
		<p class="main-title">{{$route.query.main_title}}<a @click="reload"><i class="el-icon-refresh"></i></a></p>
		<el-form ref="form" :inline="true"  :model="search_key" prop="" style="position: relative;">
			<div  style="position: absolute;right:0px;z-index: 999;">
				<el-button  type="primary" @click="dialogExportVisible=true">导出数据</el-button> 
			</div>
		    <el-form-item label="">
		        <el-input v-model="search_key.keyword" placeholder="请输入资产编码/单据编号关键字搜索" style="width:200px;"></el-input>
		    </el-form-item>
		    <el-button type="primary" @click="getData()">查询</el-button>
		</el-form>
		<el-table 
		v-loading="loading"
		element-loading-text="查询中"
		:data="tableData" 
		 border>
			<el-table-column prop="asset_code" label="资产编码">
			</el-table-column>
			<el-table-column prop="cate_name" label="资产分类">
			</el-table-column>
			<el-table-column prop="card_name" label="操作类型">
			</el-table-column>
			<el-table-column prop="content" label="操作内容">
				<template slot-scope="scope">
					<div v-for="item in scope.row.content">
						<span>{{item.title}}:{{item.value}}</span>
					</div>
				</template>
			</el-table-column>
			<el-table-column prop="receipt_number" label="操作单号">
			</el-table-column>
			<el-table-column prop="create_time" label="操作时间">
			</el-table-column>
		</el-table>
		<pagination-view :table-data-total='total' @currPage='getCurrPage' @pageSize='getPageSize'></pagination-view> 
		
		<!-- 导出弹窗 -->
		  <el-dialog title="导出筛选" :visible.sync="dialogExportVisible">
		      <el-form ref='export_form'  prop="">
		          <el-form-item label="日期范围" prop="date">
		              <el-date-picker
		                  v-model="timeSpan"
		                  type="datetimerange"
		                  range-separator="至"
		                  start-placeholder="开始日期"
		                  end-placeholder="结束日期"
		                  format="yyyy-MM-dd HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss">
		              </el-date-picker>
		          </el-form-item>
		      </el-form>
		  <div slot="footer" class="dialog-footer">
		      <el-button type="primary" @click="handleExport()">导出</el-button>
		  </div>
		  </el-dialog>
	</div>
</template>
<script>
	export default {
		inject: ['reload'],
		data() {
			return {
				loading:true,
				tableData: [],
				currentPage:1,
				pagesize:10,
				total:0,
				search_key:{
					keyword:''
				},
				dialogExportVisible:false,
				timeSpan:''
			}
		},
		created() {
			this.getData()
		},
		mounted() {

		},
		methods: {
			getData() {
				this.$api.post('AssetReport/record', {
					page:this.currentPage-1,
					limit:this.pagesize,
					keyword:this.search_key.keyword
				}, res => {
					if (res.code == 200) {
						this.tableData = res.data.list
						this.total =  res.data.count		
					} else { 
						this.$message.error(res.msg)
					}
					this.loading =  false
				})
			},
			getCurrPage(val){   //获取当前页
			    this.currentPage =  val
				this.loading =  true
			    this.getData()
			},
			getPageSize(val){   //获取每页条数
			    this.pagesize =  val
			     this.getData()
			},	
			handleExport(){
				
				location.href = '/api/admin/AssetReport/exportRecord?timeSpan='+this.timeSpan
			}
		}
	}
</script>
<style>
	.table-cell .cell{
		white-space: nowrap !important; 
	}
</style>
